list {
  list-style-position: inside;
  list-style-type: none;
}

.services_cleaning-service-table {
  box-sizing: border-box;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  width: 100%;
}

.services_cleaning-service-block {
  box-sizing: border-box;
  width: 50%;
  padding: 10px;
  min-width: 300px;
}

.services_alternate-services-table {
  box-sizing: border-box;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  width: 100%;
}

.services_alternate-service-block {
  box-sizing: border-box;
  width: 50%;
  padding: 10px;
  min-width: 300px;
}

.services_service-details-list {
  list-style-type: disc;
}