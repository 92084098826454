.ex-panel-header{

}

.ex-panel_button{
  height: 25px;
  width:  25px;
  margin: 0 2px 0 2px;
  background-image: url(/right-arrow-circular-button.svg);
  background-size: cover;
  background-color: transparent;
}
.ex-panel_button-collapsed{
  transform: rotate(0deg)
}
.ex-panel_button-expanding{
  animation-name: animate-ex-panel_button-open;
  animation-duration: 1s;
}
.ex-panel_button-expanded{
  transform: rotate(90deg);
}
.ex-panel_button-collapsing{
  animation-name: animate-ex-panel_button-close;
  animation-duration: 1s;
}
.ex-panel_button-icon{
}


@keyframes animate-ex-panel_button-open{
  from{

  }
  to{
    transform: rotate(90deg);
  }


}

@keyframes animate-ex-panel_button-close{
  from{
    transform: rotate(90deg)
  }

  to{
    transform: rotate(0deg);
  }
}
