.App {
  background-image: url(/hestia-background-temp.jpg);
  background-repeat: no-repeat;
  background-attachment: fixed;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
}

.Nav-bar {
  position: sticky;
  top: 0;
  text-align: center;
  height: 40px;
  width: 100%;
  background-color: #1A0000;
  display: flex;
  flex-flow: row nowrap;
  padding-top: 5px;
  padding-bottom: 5px;
  box-shadow: 0 0 10px #CFB53B;
  z-index: 2;
}

.Nav-button{
    width: 33%;
    font-size: 1.5em;
    color: #CFB53B;
    border-right: solid 1px #EEDD82;
    border-left: solid 1px #EEDD82;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
}

#left-nav-button {
  border-left: none;
}

#right-nav-button {
  border-right: none;
}

.Nav-bar-padder {
  height: 40px;
  width: 100%;
}

.Title {
  font-family: "Great Vibes", Helvetica, sans-serif;
  font-style: italic;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  font-size: 8vw;
  color: #CFB53B;
  text-shadow: 0px 0px 15px black;
  padding-bottom: 30px;
  white-space: nowrap;
}

.Content-panel {
  width: 65%;
  /* height: 800px; */
  box-shadow:0px 0px 150px black;
  background-color: #EEDD82;
  margin: 0 auto;
  border-radius: 2px 2px 2px 2px;
  font-family: "Cardo", sans-serif;
  color: #1A0000;
  padding: 20px;
}

.Hestia-footer{
  background-color: #1A0000;
  text-align: center;
  color: #CFB53B;
  width: 100%;
  font-size: 16px;
  margin-top: 50px;
  padding: 10px 0 10px 0;
  box-shadow: 0px 0px 10px #CFB53B;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
