.Content-cycler{
  position: relative;
  height: 300px;
  width: "100%"
}

.Content-cycler-item{
  position: absolute;
  font-size: 1.5em;
  color: #CFB53B;
  opacity: 255;
  animation-name: stable;
  animation-duration: 3s;
}

.Content-cycler-item-fade-in{
  animation-name: fade-in;
  animation-duration: 1s;
}

.Content-cycler-item-fade-out{
  animation-name: fade-out;
  animation-duration: 1s;
}

.Content-cycler-item-faded{
  animation-name: faded;
  animation-duration: 10ms;
}

@keyframes faded{
  from {
    opacity: 0;
  }
  to {
    opacity: 0;
  }
}

@keyframes stable{
  from {
    opacity: 255;
  }

  to {
    opacity: 255;
  }
}

 @keyframes fade-out{
   from{
     opacity: 255;
   }
   to{
     opacity: 0;
   }
 }

 @keyframes fade-in{
    from {
      opacity: 0;
    }
    to {
      opacity: 255;
    }
 }
