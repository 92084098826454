

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  font-family: "Great Vibes", Helvetica, sans-serif;
  font-size: 18px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button {
  background-color: none;
  border: none;
  border-radius: none;
}

a{
  text-decoration: none
}

h1{
  text-align: center;
}

@media only screen and (max-width: 1000px){
  body{
    font-size: 12px;
  }
}
