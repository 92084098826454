.Home_review-cycler{
  width: 75%;
  margin: auto;
}
.home_faq-questions{
  font-weight: bold;
}

.home_faq-answers{
  font-weight: normal;
  list-style-position: outside;
}

.home_about-us{
  border-bottom: #1A0000  2px;
  border-bottom-style: dotted;
}
