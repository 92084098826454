

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  font-family: "Great Vibes", Helvetica, sans-serif;
  font-size: 18px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button {
  background-color: none;
  border: none;
  border-radius: none;
}

a{
  text-decoration: none
}

h1{
  text-align: center;
}

@media only screen and (max-width: 1000px){
  body{
    font-size: 12px;
  }
}

.App {
  background-image: url(/hestia-background-temp.jpg);
  background-repeat: no-repeat;
  background-attachment: fixed;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
}

.Nav-bar {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  text-align: center;
  height: 40px;
  width: 100%;
  background-color: #1A0000;
  display: flex;
  flex-flow: row nowrap;
  padding-top: 5px;
  padding-bottom: 5px;
  box-shadow: 0 0 10px #CFB53B;
  z-index: 2;
}

.Nav-button{
    width: 33%;
    font-size: 1.5em;
    color: #CFB53B;
    border-right: solid 1px #EEDD82;
    border-left: solid 1px #EEDD82;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
}

#left-nav-button {
  border-left: none;
}

#right-nav-button {
  border-right: none;
}

.Nav-bar-padder {
  height: 40px;
  width: 100%;
}

.Title {
  font-family: "Great Vibes", Helvetica, sans-serif;
  font-style: italic;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  font-size: 8vw;
  color: #CFB53B;
  text-shadow: 0px 0px 15px black;
  padding-bottom: 30px;
  white-space: nowrap;
}

.Content-panel {
  width: 65%;
  /* height: 800px; */
  box-shadow:0px 0px 150px black;
  background-color: #EEDD82;
  margin: 0 auto;
  border-radius: 2px 2px 2px 2px;
  font-family: "Cardo", sans-serif;
  color: #1A0000;
  padding: 20px;
}

.Hestia-footer{
  background-color: #1A0000;
  text-align: center;
  color: #CFB53B;
  width: 100%;
  font-size: 16px;
  margin-top: 50px;
  padding: 10px 0 10px 0;
  box-shadow: 0px 0px 10px #CFB53B;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.ex-panel-header{

}

.ex-panel_button{
  height: 25px;
  width:  25px;
  margin: 0 2px 0 2px;
  background-image: url(/right-arrow-circular-button.svg);
  background-size: cover;
  background-color: transparent;
}
.ex-panel_button-collapsed{
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg)
}
.ex-panel_button-expanding{
  -webkit-animation-name: animate-ex-panel_button-open;
          animation-name: animate-ex-panel_button-open;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
}
.ex-panel_button-expanded{
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
.ex-panel_button-collapsing{
  -webkit-animation-name: animate-ex-panel_button-close;
          animation-name: animate-ex-panel_button-close;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
}
.ex-panel_button-icon{
}


@-webkit-keyframes animate-ex-panel_button-open{
  from{

  }
  to{
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }


}


@keyframes animate-ex-panel_button-open{
  from{

  }
  to{
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }


}

@-webkit-keyframes animate-ex-panel_button-close{
  from{
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg)
  }

  to{
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

@keyframes animate-ex-panel_button-close{
  from{
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg)
  }

  to{
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

.Home_review-cycler{
  width: 75%;
  margin: auto;
}
.home_faq-questions{
  font-weight: bold;
}

.home_faq-answers{
  font-weight: normal;
  list-style-position: outside;
}

.home_about-us{
  border-bottom: #1A0000  2px;
  border-bottom-style: dotted;
}

.Content-cycler{
  position: relative;
  height: 300px;
  width: "100%"
}

.Content-cycler-item{
  position: absolute;
  font-size: 1.5em;
  color: #CFB53B;
  opacity: 255;
  -webkit-animation-name: stable;
          animation-name: stable;
  -webkit-animation-duration: 3s;
          animation-duration: 3s;
}

.Content-cycler-item-fade-in{
  -webkit-animation-name: fade-in;
          animation-name: fade-in;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
}

.Content-cycler-item-fade-out{
  -webkit-animation-name: fade-out;
          animation-name: fade-out;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
}

.Content-cycler-item-faded{
  -webkit-animation-name: faded;
          animation-name: faded;
  -webkit-animation-duration: 10ms;
          animation-duration: 10ms;
}

@-webkit-keyframes faded{
  from {
    opacity: 0;
  }
  to {
    opacity: 0;
  }
}

@keyframes faded{
  from {
    opacity: 0;
  }
  to {
    opacity: 0;
  }
}

@-webkit-keyframes stable{
  from {
    opacity: 255;
  }

  to {
    opacity: 255;
  }
}

@keyframes stable{
  from {
    opacity: 255;
  }

  to {
    opacity: 255;
  }
}

 @-webkit-keyframes fade-out{
   from{
     opacity: 255;
   }
   to{
     opacity: 0;
   }
 }

 @keyframes fade-out{
   from{
     opacity: 255;
   }
   to{
     opacity: 0;
   }
 }

 @-webkit-keyframes fade-in{
    from {
      opacity: 0;
    }
    to {
      opacity: 255;
    }
 }

 @keyframes fade-in{
    from {
      opacity: 0;
    }
    to {
      opacity: 255;
    }
 }

list {
  list-style-position: inside;
  list-style-type: none;
}

.services_cleaning-service-table {
  box-sizing: border-box;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  width: 100%;
}

.services_cleaning-service-block {
  box-sizing: border-box;
  width: 50%;
  padding: 10px;
  min-width: 300px;
}

.services_alternate-services-table {
  box-sizing: border-box;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  width: 100%;
}

.services_alternate-service-block {
  box-sizing: border-box;
  width: 50%;
  padding: 10px;
  min-width: 300px;
}

.services_service-details-list {
  list-style-type: disc;
}
.contact-us_contact-info{
  display: flex;
  flex-flow: row nowrap;
}

.contact-us_contact-info-block{
  width: 50%;
}

